import { apiAxios } from '../utils/request.js'

// 获取etsy连接url
export const getEtsyConnectUrl = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/etsy/connect/url/get', data, Istoast, Send)
}

export const submitEtsyConnect = (data, Istoast = false, Send = false) => {
  return apiAxios('post', '/shopapi/shop/etsy/connect/submit', data, Istoast, Send)
}
