<template>
  <div class="default-main">
    <div class="page">
      <div v-if="status === enums.statusEnum.DOING" class="page-status">
        <i class="el-icon-loading" />
        <div class="status-text">Connecting... Please wait for a few second</div>
      </div>
      <div v-if="status === enums.statusEnum.SUCCESS" class="page-status">
        <i class="el-icon-success" style="color:#7367F0" />
        <div class="status-text">Connect etsy shop success!</div>
      </div>
      <div v-if="status === enums.statusEnum.FAIL" class="page-status">
        <i class="el-icon-error" style="color:#FF4D4F" />
        <div class="status-text">Connect etsy shop fail!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { submitEtsyConnect } from '@/api/etsy'
var statusEnum = {
  DOING: 1,
  SUCCESS: 2,
  FAIL: 3
}
export default {
  name: 'EtsyInstall',
  data() {
    return {
      status: statusEnum.DOING,
      enums: {
        statusEnum
      }
    }
  },
  inject: ['reload'],
  mounted() {
    var code = this.getQueryString('code')
    var state = this.getQueryString('state')
    var error = this.getQueryString('error')
    if (error) {
      this.status = statusEnum.FAIL
      this.$message.warning('connect etsy shop cancel!')
      // this.callNewPage('/SetUp/Stores')
      return
    }
    submitEtsyConnect({ code: code, state: state }).then(response => {
      if (response.code === 200) {
        this.status = statusEnum.SUCCESS
        this.$message.success('connect etsy shop success!')
        this.callNewPage('/account?type=4')
      } else {
        this.status = statusEnum.FAIL
        this.$message.error(response.msg)
      }
    })
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')

      var r = window.location.search.substr(1).match(reg)

      if (r != null) {
        return unescape(r[2])
      }

      return null
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  margin-top: 60px;
  background: #FFFFFF;
  box-shadow: 0px 5px 29px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 130px;
  line-height: 1;
  .page-title-box {
    display: flex;
    align-items: center;
    .page-logo {
      width: 60px;
      height: 60px;
    }
    .page-title {
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
    }
  }
  .page-status {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .status-text {
      margin-left: 20px;
      font-size: 16px;
      font-weight: bold;
      color: #000000;
    }
  }
  .page-button {
    margin-top: 30px;
  }
}
</style>
